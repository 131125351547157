@import '../../../variables.scss';
.articles-head {
    width: 100%;
    &__background {
        position: relative;
        width: 100%;
        height: $resources-head-height;
        background: url('../../../assets/images/articles/BHRC_news_cover.jpg');
        background-size: cover;
        background-position-y: center;
    }
    &__text {
        height: $resources-head-height;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            color: #fff;
        }
        p {
            color: #fff;
            max-width: $news-p-height;
        }
    }
}

@media screen and (min-width: $width-4K) {
    .articles-head {
        &__background {
            height: $resources-head-height*2;
        }
        &__text {
            height: $resources-head-height*2;
            p {
                max-width: $news-p-height*2;
            }
        }
    }
}