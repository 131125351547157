@import '../../../variables.scss';
.profile {
    margin-bottom: 48px;
    &__about {
        width: 630px;
        margin-top: 39px;
        &-me {
            width: 630px;
            padding: 42px;
            background-color: $urbanica-pink;
            border-radius: 39px;
            margin-top: 50px;
            &:hover {
                box-shadow: $profile-shadow;
            }
            &:hover &__edit {
                display: flex !important;
            }
            &__info {
                margin-top: 20px;
                color: $main-blue;
                font-size: 20px;
                line-height: 1.2;
                textarea {
                    border: none;
                    background-color: transparent;
                    width: 100%;
                    &:focus {
                        border: none;
                        outline: none;
                    }
                }
            }
            &__edit {
                position: absolute;
                display: none;
                font-size: 30px;
                width: 50px;
                height: 50px;
                align-items: center;
                justify-content: center;
                right: 42px;
                top: 42px;
                border-radius: 50%;
                cursor: pointer;
                svg {
                    transform: rotate(0deg);
                }
                &:hover {
                    background-color: rgba(206, 206, 206, 0.25);
                    
                    svg {
                        transform: rotate(360deg);
                        transition: transform 0.5s ease-in-out;
                    }
                }
            }
        }
    }
    &__statistics {
        display: flex;
        justify-content: space-around;
        .statistics-badge {
            margin: 0 auto;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 49px;
            height: 49px;
            border-radius: 50%;
            box-shadow: $standart-shadow;
            color: $main-blue;
            font-size: 20px;
            font-weight: 600;
        }
        label {
            font-size: 25px;
            font-weight: 600;
            color: $main-blue;
            text-align: center;
        }
    }
    &-badge {
        height: 67px;
        background-color: $main-blue;
        padding: 0 20px;
        width: fit-content;
        color: #fff;
        border-radius: 16px;
        font-size: 25px;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__works {
        margin-top: 36px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        .left-corner {
            position: absolute;
            top: 0;
            left: 0;
        }
        .right-corner {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: rotate(180deg);
        }
    }
    &-files {
        width: 852px;
        margin: 50px 0 100px;
        &__file {
            cursor: pointer;
            background-color: $urbanica-light-pink;
            border-radius: 28px;
            padding: 24px;
            width: 100%;
            display: flex;
            color: $main-blue;
            margin-bottom: 43px;
            &:hover {
                box-shadow: $profile-shadow;
            }
            .file-title {
                font-weight: 600;
                font-size: 20px;
            }
            .file-description {
                font-size: 15px;
            }
        }
        &__details {
            padding: 0 24px;
            margin-top: -43px;
            opacity: 0;
            &.active {
                opacity: 1;
                transition: opacity 0.5s;
            }
            th {
                background-color: $urbanica-light-pink !important;
            }
        }
    }
}