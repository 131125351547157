@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap');
@import "~bootstrap/scss/bootstrap";
@font-face {
    font-family: 'candara-italic';
    src: url('./assets/fonts/candara-light-italic.ttf') format('truetype');
    font-weight: normal;
}

@font-face {
    font-family: 'candara-italic';
    src: url('./assets/fonts/candara-bold-italic.woff') format('truetype');
    font-weight: bold;
}

body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    $font-size: $main-font-size;
    //font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    //padding-bottom: $footer-height;
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-inner {
        z-index: 1;
        width: $standart-container-width;
        &.urbanica-container {
            width: $urbanica-container-width;
            padding: $topics-y $topics-x;
            box-shadow: 0 3px 6px rgba(#000000, 0.16);
            margin-bottom: $big-margin;
        }
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-around {
    justify-content: space-around !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.align-top {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    // background-color: $blue-opacity;
    background-color: rgba(256, 256, 256, 0.1);
    z-index: 1;
}

.relative {
    position: relative;
    z-index: 2;
}

.paint-badge {
    width: $paint-width;
    height: $paint-height;
    position: relative;
    .st0 {
        fill: $main-orange;
    }
    .badge-label {
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: $main-font-size;
        //width: $paint-width;
        //height: $paint-height;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        transform: translate(-50%, -50%);
        color: #fff;
    }
}


/* Titles */

h1,
h2 {
    font-family: 'candara-italic';
    font-weight: 600 !important;
}

h1,
h2,
h3 {
    margin: $small-margin 0 !important;
}

h1 {
    font-size: $h1-font-size !important;
}

h2 {
    font-size: $h2-font-size !important;
    //color: $main-blue;
    margin: $medium-margin 0 $big-margin 0;
}

h3 {
    font-size: $h3-font-size !important;
    line-height: 1.5 !important;
    font-weight: normal !important;
}

h1.title {
    margin-bottom: $big-margin !important;
}

.lined-title {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid $main-orange;
    line-height: 0.1em;
    margin: 10px 0 20px !important;
    span {
        background: #fff;
        padding: 0 10px;
    }
}

.upper-case {
    text-transform: uppercase;
}


/* Buttons */

.bhrc-btn {
    padding: 0 $button-padding;
    font-size: $button-font-size;
    border-radius: $button-radius;
    height: $button-height;
    border: none;
    display: flex;
    align-items: center;
    &:focus {
        border: none;
        outline: none;
    }
    svg {
        margin-left: $small-margin;
    }
}

.orange-btn {
    background-color: $main-orange;
    color: #fff;
    margin-right: $orange-margin-right;
    &:hover {
        //background-color: $main-orange-hover;
        background-color: $main-blue;
    }
    &:last-child {
        margin-right: 0;
    }
}

.blue-btn {
    background-color: $main-blue;
    color: #fff;
    &:hover {
        //background-color: $main-blue-hover;
        background-color: $main-orange;
    }
}

.white-btn {
    background-color: #fff;
    color: $main-orange;
    height: $white-button-height;
    font-size: $text-font-size;
    &:hover {
        background-color: $main-white-hover;
    }
}

.urbanica-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: $urbanica-border-radius;
    border: none;
    &:focus {
        border: none;
        outline: none;
    }
    &.sign-up {
        height: $urbanica-signup-button-height;
        padding: 0 $urbanica-button-padding;
        font-size: $urbanica-button-font-size;
        font-weight: 600;
        color: #fff;
        background-color: $urbanica-orange;
        &:hover {
            background-color: $main-blue;
        }
    }
    &.big-blue {
        height: $urbanica-signup-button-height;
        padding: 0 $urbanica-button-padding;
        font-size: $urbanica-button-font-size;
        font-weight: 600;
        color: #fff;
        background-color: $main-blue;
        &:hover {
            background-color: $urbanica-orange;
        }
    }
    &.orange-btn {
        background-color: $urbanica-orange;
        padding: 0 $urbanica-orange-padding;
        height: $urbanica-orange-button-height;
        font-size: $main-font-size;
        font-weight: 600;
    }
    &.blue-btn {
        background-color: $main-blue;
        font-family: 'Candara';
        min-width: 64px;
        height: 48px;
        font-size: 30px;
        font-style: italic;
        font-weight: bold;
        border-radius: $urbanica-input-radius;
        text-align: center;
        justify-content: center;
        &:hover {
            background-color: $main-blue-hover;
        }
    }
}

.competition-btn {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50.35%;
    bottom: 30px;
    z-index: 10;
    width: 76px;
    height: 72px;
    border-radius: 50%;
    font-size: 26px;
    font-weight: 600;
    color: #fff;
    justify-content: center;
    background-color: #f79501;
    border: none;
    &:hover {
        background-color: $main-blue;
    }
}


/* Margins */

.small-margin-top {
    margin-top: $small-margin;
}


/* Colors */

.white-text {
    color: #fff;
}

.main-blue-text {
    color: $main-blue;
}

.main-orange-text {
    color: $main-orange;
}

.main-grey-text {
    color: $block-text-color;
}

.white-backgound {
    background-color: #fff;
}

.main-blue-background {
    background-color: $main-blue;
}

.main-orange-background {
    background-color: $main-orange;
}

.red-text {
    color: $error-red;
}


/* Text */

.hashtag-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: $hashtag-font-size;
}

.block-title-text {
    font-family: Arial, Helvetica, sans-serif;
    font-size: $block-title-font-size;
    font-weight: 600;
}

.nowrap-text {
    white-space: nowrap;
}

.centered-text {
    text-align: center;
}

.noncentered-text {
    text-align: left;
}

.block-text {
    line-height: 2;
    font-size: $text-font-size;
    color: $block-text-color;
}

.candara {
    font-family: 'Candara';
}

.regular-font {
    font-weight: normal !important;
}

.italic-text {
    font-style: italic !important;
}

.bold-text {
    font-weight: 600 !important;
}

.bold-italic-text {
    font-style: italic !important;
    font-weight: 600 !important;
}


/* Modals */

.modal-dialog {
    max-width: $modal-max-width;
}

.modal-content {
    padding: $modal-padding;
    width: $modal-content-width;
    height: $modal-content-height;
    background-size: 100%;
    align-items: flex-end;
    &__fields {
        width: $modal-field-width;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: $modal-field-top;
        form {
            display: flex;
            flex-direction: column;
        }
        h1 {
            text-align: center;
        }
        input {
            width: $modal-input-width;
            height: $modal-input-height;
            border: none;
            outline: none;
            border-radius: $button-radius;
            margin: $modal-input-margin 0;
            font-size: $text-font-size;
            padding: $small-padding;
        }
        .bhrc-btn {
            height: $bhrc-button-height;
            padding: 0 $little-padding;
            svg {
                margin: 0;
            }
            &.orange-btn {
                background-color: #F79501;
            }
        }
    }
    &__footer {
        display: flex;
        justify-content: flex-end;
        width: $modal-footer-width;
        margin-top: $modal-footer-top;
    }
}

.urbanica-modal {
    .modal-content {
        background-image: url("/assets/images/urbanica/modal-back.png");
    }
    .register-btn {
        position: absolute;
        bottom: 20px;
        right: 65px;
    }
}


/* Badges */

.urbanica-badge {
    border-radius: 50%;
    width: $urbanica-badge-round;
    height: $urbanica-badge-round;
    background-color: $main-orange;
    color: #fff;
    font-family: 'Candara';
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    display: inline-block;
}

.with-badge {
    margin-left: 15px;
}

.success-icon {
    color: $success-green;
    margin-right: 10px;
}

@media screen and (min-width: $width-4K) {
    .container {
        max-width: 2280px !important;
        &-inner {
            width: $standart-container-width*2;
            &.urbanica-container {
                width: $urbanica-container-width*2;
                padding: $topics-y*2 $topics-x*2;
            }
        }
    }
    .paint-badge {
        width: $paint-width*2;
        height: $paint-height*2;
        .badge-label {
            font-size: $main-font-size*2;
        }
    }
    /* Titles */
    h3 {
        margin: $small-margin*2 0 !important;
    }
    h1 {
        font-size: $h1-font-size*2 !important;
    }
    h2 {
        font-size: $h2-font-size*2 !important;
        margin: $medium-margin*2 0 $big-margin*2 0;
    }
    h3 {
        font-size: $h3-font-size*2 !important;
    }
    .block-text {
        font-size: $text-font-size*2;
    }
    h1.title {
        margin-bottom: $big-margin*2 !important;
    }
    /* Buttons */
    .bhrc-btn {
        padding: 0 $button-padding*2;
        font-size: $button-font-size*2;
        border-radius: $button-radius*2;
        height: $button-height*2;
        svg {
            margin-left: $small-margin*2;
        }
    }
    .orange-btn {
        margin-right: $orange-margin-right*2;
    }
    .white-btn {
        height: $white-button-height*2;
        font-size: $text-font-size*2;
    }
    .urbanica-btn {
        border-radius: $urbanica-border-radius*2;
        &.sign-up {
            height: $urbanica-signup-button-height*2;
            padding: 0 $urbanica-button-padding*2;
            font-size: $urbanica-button-font-size*2;
        }
        &.orange-btn {
            padding: 0 $urbanica-orange-padding*2;
            height: $urbanica-orange-button-height*2;
            font-size: $main-font-size*2;
        }
    }
    /* Margins */
    .small-margin-top {
        margin-top: $small-margin*2;
    }
    /* Text */
    .hashtag-text {
        font-size: $hashtag-font-size*2;
    }
    .block-title-text {
        font-size: $block-title-font-size*2;
    }
    /* Modals */
    .modal-dialog {
        max-width: $modal-max-width*2;
    }
    .modal-content {
        padding: $modal-padding*2;
        width: $modal-content-width*2;
        height: $modal-content-height*2;
        &__fields {
            width: $modal-field-width*2;
            padding-top: $modal-field-top*2;
            input {
                width: $modal-input-width*2;
                height: $modal-input-height*2;
                border-radius: $button-radius*2;
                margin: $modal-input-margin*2 0;
                font-size: $text-font-size*2;
                padding: $small-padding*2;
            }
            .bhrc-btn {
                height: $bhrc-button-height*2;
                padding: 0 $little-padding*2;
            }
        }
        &__footer {
            width: $modal-footer-width*2;
            margin-top: $modal-footer-top*2;
        }
    }
}

@media screen and (max-width: $width-first) {
    .container {
        &-inner {
            width: 100vw;
            &.urbanica-container {
                width: 100vw;
            }
        }
    }
    .main-about-flex {
        flex-direction: column !important;
        align-items: center !important;
    }
}

@media screen and (max-width: $width-mobile) {
    .desktop {
        display: none !important;
    }
    .container {
        &-inner {
            width: 100vw;
            padding: 0 10%;
            &.urbanica-container {
                width: 100vw;
            }
        }
    }
}

@media screen and (min-width: ($width-mobile + 1)) {
    .mobile {
        display: none !important;
    }
}