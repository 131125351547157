@import '../../variables.scss';
.modal-row {
    display: flex;
    position: relative;
}

.modal-input {
    width: 200px;
    height: 18px;
    border: none;
    outline: none;
    border-radius: 20px;
    margin: 15px 0;
    font-size: 13px;
    padding: 10px;
    &.error {
        border: 2px solid $error-red;
    }
    &__error {
        margin-top: -10px;
        font-size: $main-font-size;
        position: absolute;
        top: 45px;
        color: #fff;
        padding: 0 10px;
        border-radius: 7px;
        background-color: rgba(244, 67, 54, 0.76);
    }
}

.modal-textarea {
    width: 200px;
    min-height: 100px;
    border: none;
    outline: none;
    border-radius: 20px;
    margin: 15px 0;
    font-size: 13px;
    padding: 10px;
}

.urbanica-input {
    border-radius: $urbanica-input-radius;
    border: none;
    outline: none;
    background-color: $urbanica-pink;
    height: 48px;
    padding: 0 $menu-padding;
    width: 100%;
    &.error {
        border: 1px solid $error-red;
        &::placeholder {
            color: $error-red;
        }
    }
}

.urbanica-textarea {
    border-radius: $urbanica-input-radius;
    border: none;
    outline: none;
    background-color: $urbanica-pink;
    padding: $menu-padding;
    width: 100%;
    min-height: 135px;
    &.error {
        border: 1px solid $error-red;
        &::placeholder {
            color: $error-red;
        }
    }
}

.urbanica-select {}

.css-g1d714-ValueContainer {
    border-radius: $urbanica-input-radius;
    border: none;
    outline: none;
    background-color: $urbanica-pink;
    padding: $menu-padding;
    height: 48px;
}

.css-yk16xz-control {
    border: none !important;
    background-color: $urbanica-pink !important;
    border-radius: $urbanica-input-radius !important;
    width: 80px !important;
    &:focus {
        outline: none;
        border: none;
    }
}

.css-1pahdxg-control {
    border: none !important;
    background-color: $urbanica-pink !important;
    border-radius: $urbanica-input-radius !important;
    width: 80px !important;
    &:focus {
        outline: 0 !important;
    }
}

.css-26l3qy-menu {
    background-color: $urbanica-pink !important;
    border-radius: $urbanica-input-radius !important;
    width: 80px !important;
}

.alerts-box {
    position: absolute;
    width: 100%;
    &__message {
        position: relative;
        margin-bottom: 20px;
        z-index: 10000;
        width: 50%;
        color: #fff;
        padding: 20px;
        text-align: center;
        left: 50%;
        transform: translate(-50%, 30px);
        opacity: 0;
        border-radius: $standart-radius;
        &.error {
            opacity: 1;
            background-color: $error-red;
            transition: opacity 1ms;
        }
        &.success {
            opacity: 1;
            background-color: $success-green;
            transition: opacity 1ms;
        }
        &.warning {
            opacity: 1;
            background-color: $warning-orange;
            transition: opacity 1ms;
        }
        &.basic {
            opacity: 1;
            background-color: $info-blue;
            transition: opacity 1ms;
        }
    }
}

.bhrc-pagination {
    display: flex;
    justify-content: center;
    &__badge {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: $standart-shadow;
        font-family: 'Candara';
        font-style: italic;
        font-weight: 600;
        font-size: 20px;
        color: $main-black;
        cursor: pointer;
        &:hover {
            background-color: $main-orange;
            color: #fff;
            text-decoration: none;
        }
        &:last-child {
            margin-right: 0;
        }
        &.arrow {
            background-color: $main-blue;
            color: #fff;
        }
        &.active {
            background-color: $main-orange;
            color: #fff;
        }
    }
}