@import '../../../variables.scss';
.cover {
    width: 100%;
    height: calc(100vh - #{$cover-photo-margin});
    position: relative;
    //background-attachment: fixed;
    background-image: url("/assets/images/bhrc_cover.webp");
    background-size: cover;
    background-position-y: 60%;
    &__search {
        z-index: 2;
        margin-top: $search-margin;
        input[type='text'] {
            width: 0;
            padding: 0;
            height: $main-search-height;
            border-radius: $main-search-radius;
            border: none;
            font-weight: 600;
            transition: 0.3s width, opacity;
            &.active {
                width: $main-search-width;
                opacity: 1;
                transition: 0.3s width, opacity;
                padding: 0 20px;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: #000;
            }
        }
        .search-item {
            padding: 20px;
            max-height: 200px;
            overflow: auto;
            &__category {
                font-weight: 600;
                border-bottom: 2px dashed #cecece;
            }
            &__item {
                border-bottom: 1px solid #cecece;
                padding: 10px 0;
                display: block;
                text-decoration: none;
                &:hover {
                    background-color: #f7f5f5;
                }
                &.highlighted {
                    background-color: #f7f5f5;
                }
            }
        }
    }
    &__slogan {
        position: absolute;
        z-index: 1;
        top: 43%;
        transform: translate(0, -50%);
        font-family: 'candara-italic';
        .st0 {
            fill: $main-orange;
        }
        .slogan {
            color: #fff;
            font-size: $h2-font-size;
            font-weight: 600;
            width: 60%;
            position: absolute;
            line-height: 1.5;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            p {
                margin: 0;
            }
        }
    }
    &__urbanica {
        z-index: 1;
        position: absolute;
        bottom: $urbanica-button-bottom;
        svg {
            cursor: pointer;
            #Image_24-2:hover {
                fill: #6499d9;
            }
        }
    }
}

.main-about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: $block-padding 0;
    height: $screen-height;
    &__left {
        width: $left-side-width;
    }
    &__right {
        position: relative;
        width: $right-side-width;
        .about-circle-images {
            .small-circle {
                width: $litle-circle-size;
                height: $litle-circle-size;
                border: $circle-border-size solid #fff;
                border-radius: 50%;
                position: absolute;
                top: $small-circle-top;
                z-index: 2;
                image-rendering: pixelated;
                &__animate {
                    position: absolute;
                    z-index: 2;
                }
            }
            .big-circle {
                width: $big-circle-size;
                height: $big-circle-size;
                border-radius: 50%;
                position: absolute;
                right: 0;
                z-index: -1;
            }
        }
    }
    &__footer {
        margin-top: $big-margin;
        display: flex;
    }
}

.main-activity {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: $main-activity-height;
    height: $screen-height;
    &__background {
        position: relative;
        width: 100%;
        height: $main-activity-back-height;
        background: url('../../../assets/images/bhrc_news.webp');
        background-size: cover;
        background-position-y: $main-activity-y;
    }
    &__central-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: $central-subtitle-width;
        text-align: center;
    }
    &__activities {
        z-index: 4;
        margin-top: -185px;
    }
    .activity-block {
        width: $main-activity-width;
        height: $main-activity-height;
        background-color: #fff;
        border-radius: $standart-radius;
        -webkit-box-shadow: $standart-shadow;
        -moz-box-shadow: $standart-shadow;
        box-shadow: $standart-shadow;
        padding: $carousel-block-padding-v $carousel-block-padding-h;
        font-size: $location-font-size;
        margin-right: $medium-margin;
        &:last-child {
            margin-right: 0;
        }
        .time-and-location {
            display: flex;
            color: $block-text-color;
            margin-top: $small-margin;
            span {
                font-family: Arial, Helvetica, sans-serif;
                font-weight: 600;
                font-style: italic;
                margin-left: $small-margin;
                margin-right: $small-margin;
                &:last-child {
                    margin-right: 0;
                }
            }
            svg {
                width: $small-icon-size;
                height: $small-icon-size;
                path {
                    fill: $block-text-color;
                }
            }
        }
        &__description {
            margin-top: $search-margin;
            margin-bottom: $small-margin;
            font-size: $location-font-size;
        }
        &__join {
            color: $main-orange;
            font-size: $menu-font-size;
            margin-top: $big-margin;
        }
    }
    .carousel-inner {
        overflow: visible;
        margin: 0 50%;
        width: fit-content;
        transform: translate(-50%, 0);
        .carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
            transform: translateX(-200%);
        }
        .carousel-item-prev:not(.carousel-item-left), .active.carousel-item-right {
            transform: translateX(200%);
        }
    }
    .carousel-indicators {
        bottom: $main-indicator-bottom !important;
        li {
            width: $main-indicator-width;
            height: $main-indicator-height;
            border-radius: $indicator-radius;
            border: 1px solid $main-blue;
            background-color: #fff;
        }
        .active {
            background-color: $main-blue;
        }
    }
}

.main-statistics {
    width: 100%;
    height: 153px;
    background-color: $main-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    &__inner {
        padding: 0 78px;
        height: 88px;
        border-right: 2px solid $border-on-grey;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 40px;
        color: $main-blue;
        &:last-child {
            border: none;
        }
        label {
            position: absolute;
            top: 10px;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: $little-font-size;
            color: $block-text-color;
        }
    }
}

.main-resources {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: $screen-height;
    padding: $block-padding 0;
    &__blocks {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: $big-margin 0;
        .resource-block {
            width: $main-resource-width;
            height: $main-resource-height;
            border-radius: $resources-radius;
            position: relative;
            background-size: cover;
            &__content {
                position: absolute;
                overflow: hidden;
                width: $main-resource-width;
                height: $main-resource-small;
                border-radius: $resources-radius-big $resources-radius-big $resources-radius $resources-radius;
                background-color: $main-blue;
                color: #fff;
                bottom: 0;
                padding: $small-padding;
                transition: 0.3s height, background-color;
                &:hover {
                    background-color: $main-orange;
                    height: $main-resource-hover;
                    transition-duration: 0.3s;
                    .indicator {
                        &__progress {
                            background-color: $main-blue;
                        }
                    }
                }
                .block-title-text {
                    margin-top: $modal-footer-top;
                }
                .text {
                    font-size: $text-font-size;
                    font-family: Arial, Helvetica, sans-serif;
                    margin-bottom: $medium-margin;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                .statistics {
                    margin-bottom: $medium-margin+3;
                    font-family: Arial, Helvetica, sans-serif;
                    font-size: $text-font-size;
                }
                .indicator {
                    height: $little-height;
                    background-color: $progress-color;
                    &__progress {
                        width: 50%;
                        background-color: #fff;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.main-sign-in {
    width: 100%;
    min-height: $main-sign-in;
    //background-color: $main-blue;
    background-image: url("/assets/images/signin-background.svg");
    background-size: cover;
    display: flex;
    justify-content: center;
    padding: $medium-padding 0;
    color: #fff;
    position: relative;
    &__left {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        height: 230px;
    }
    &__right {
        position: absolute;
        top: 0;
        right: 0;
        overflow: hidden;
        height: 230px;
    }
    .start {
        width: calc(190px * 3);
        height: calc(164px * 3);
        margin-top: -88px;
    }
    .end {
        width: calc(190px * 3);
        height: calc(164px * 3);
        margin-top: -88px;
        transform: rotate(180deg);
    }
    h1 {
        margin: 0 !important;
    }
    &__container {
        width: $main-signin-container;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
    }
}

.main-team {
    padding: $big-margin 0;
    h1 {
        color: $main-blue;
    }
    &__members {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        margin-top: $medium-margin;
        .member {
            cursor: pointer;
            width: $member-width;
            padding: $menu-margin;
            border-radius: $resources-radius;
            &:hover {
                box-shadow: 6px 2px 6px 0 rgba(0, 0, 0, 0.16);
            }
            display: flex;
            flex-direction: column;
            align-items: center;
            font-family: Arial,
            Helvetica,
            sans-serif;
            img {
                margin-bottom: $big-margin;
                width: $main-logo-size;
                height: $main-logo-size;
                border-radius: 50%;
                object-fit: cover;
            }
            .name {
                font-size: $h3-font-size;
            }
            .profession {
                font-size: $little-font-size;
                color: $main-orange;
                margin-bottom: $small-margin;
            }
            .social-network {
                color: $main-orange;
                .icon {
                    margin-left: $small-margin;
                    svg {
                        width: $social-icon;
                        height: $social-icon;
                        &:hover {
                            width: $social-icon-hover;
                            height: $social-icon-hover;
                        }
                    }
                    path {
                        fill: $main-orange;
                    }
                }
            }
        }
    }
}

.main-bot {
    padding: $big-margin 0;
    display: flex;
    justify-content: center;
}

.member-modal {
    .modal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-family: Arial, Helvetica, sans-serif;
        height: auto;
        img {
            margin-bottom: $medium-margin;
            width: $main-logo-size;
            height: $main-logo-size;
            border-radius: 50%;
            object-fit: cover;
        }
        .name {
            font-size: $h3-font-size;
        }
        .profession {
            font-size: $little-font-size;
            color: $main-orange;
            margin-bottom: $small-margin;
        }
        .social-network {
            color: $main-orange;
            .icon {
                margin-left: $small-margin;
                svg {
                    width: $social-icon;
                    height: $social-icon;
                }
                path {
                    fill: $main-orange;
                }
            }
        }
        .bio {
            margin-top: $medium-margin;
            font-size: $language-font-size;
            color: $block-text-color;
        }
    }
}


/* FOR 4K monitors */

@media screen and (min-width: $width-4K) {
    .cover {
        height: calc(100vh - #{$cover-photo-margin*2});
        &__search {
            margin-top: $search-margin*2;
            input[type='text'] {
                height: $main-search-height*2;
                border-radius: $main-search-radius*2;
                &.active {
                    width: $main-search-width;
                    padding: 0 40px;
                }
            }
        }
        &__slogan {
            .slogan {
                font-size: $h2-font-size*2;
            }
            svg {
                width: 692px;
                height: 404px;
            }
        }
        &__urbanica {
            bottom: $urbanica-button-bottom*2;
            svg {
                width: 313.310px;
                height: 151.546px;
            }
        }
    }
    .main-about {
        padding: $block-padding*2 0;
        &__left {
            width: $left-side-width*2;
        }
        &__right {
            width: $right-side-width*2;
            .about-circle-images {
                .small-circle {
                    width: $litle-circle-size*2;
                    height: $litle-circle-size*2;
                    border: $circle-border-size*2 solid #fff;
                    top: $small-circle-top*2;
                }
                .big-circle {
                    width: $big-circle-size*2;
                    height: $big-circle-size*2;
                }
            }
        }
        &__footer {
            margin-top: $big-margin*2;
        }
    }
    .main-activity {
        height: $main-activity-height*2;
        &__background {
            height: $main-activity-back-height*2;
            background-position-y: $main-activity-y*2;
        }
        &__central-block {
            width: $central-subtitle-width*2;
        }
        &__activities {
            bottom: $main-activity-bottom*2;
        }
        .activity-block {
            width: $main-activity-width*2;
            height: $main-activity-height*2;
            border-radius: $standart-radius*2;
            padding: $carousel-block-padding-v*2 $carousel-block-padding-h*2;
            font-size: $location-font-size*2;
            margin-right: $medium-margin*2;
            .time-and-location {
                margin-top: $small-margin*2;
                span {
                    margin-left: $small-margin*2;
                    margin-right: $small-margin*2;
                }
                svg {
                    width: $small-icon-size*2;
                    height: $small-icon-size*2;
                }
            }
            &__description {
                margin-top: $search-margin*2;
                margin-bottom: $small-margin*2;
                font-size: $location-font-size*2;
            }
            &__join {
                font-size: $menu-font-size*2;
                margin-top: $big-margin*2;
            }
        }
        .carousel-indicators {
            bottom: $main-indicator-bottom*2 !important;
            li {
                width: $main-indicator-width*2;
                height: $main-indicator-height*2;
                border-radius: $indicator-radius*2;
                border: 2px solid $main-blue;
            }
        }
    }
    .main-resources {
        margin-top: 480px;
        padding: $block-padding*2 0;
        &__blocks {
            margin: $big-margin*2 0;
            .resource-block {
                width: $main-resource-width*2;
                height: $main-resource-height*2;
                border-radius: $resources-radius*2;
                &__content {
                    width: $main-resource-width*2;
                    height: $main-resource-small*2;
                    border-radius: $resources-radius-big*2 $resources-radius-big*2 $resources-radius*2 $resources-radius*2;
                    padding: $small-padding*2;
                    &:hover {
                        height: $main-resource-hover*2;
                    }
                    .block-title-text {
                        margin-top: $modal-footer-top*2;
                    }
                    .text {
                        font-size: $text-font-size*2;
                        margin-bottom: $medium-margin*2;
                    }
                    .statistics {
                        margin-bottom: ($medium-margin+3)*2;
                        font-size: $text-font-size*2;
                    }
                    .indicator {
                        height: $little-height*2;
                    }
                }
            }
        }
    }
    .main-sign-in {
        min-height: $main-sign-in*2;
        padding: $medium-padding 0;
        &__container {
            width: $main-signin-container*2;
        }
        .start,
        .end {
            width: 380px;
            height: 328px;
        }
    }
    .main-team {
        padding: $big-margin*2 0;
        &__members {
            margin-top: $medium-margin*2;
            .member {
                width: $member-width*2;
                padding: $menu-margin*2;
                border-radius: $resources-radius*2;
                img {
                    margin-bottom: $big-margin*2;
                    width: $main-logo-size*2;
                    height: $main-logo-size*2;
                }
                .name {
                    font-size: $h3-font-size*2;
                }
                .profession {
                    font-size: $little-font-size*2;
                    margin-bottom: $small-margin*2;
                }
                .social-network {
                    .icon {
                        margin-left: $small-margin*2;
                        svg {
                            width: $social-icon*2;
                            height: $social-icon*2;
                            &:hover {
                                width: $social-icon-hover*2;
                                height: $social-icon-hover*2;
                            }
                        }
                    }
                }
            }
        }
    }
    .member-modal {
        .modal-content {
            img {
                margin-bottom: $medium-margin*2;
                width: $main-logo-size*2;
                height: $main-logo-size*2;
            }
            .name {
                font-size: $h3-font-size*2;
            }
            .profession {
                font-size: $little-font-size*2;
                margin-bottom: $small-margin*2;
            }
            .social-network {
                .icon {
                    margin-left: $small-margin*2;
                    svg {
                        width: $social-icon*2;
                        height: $social-icon*2;
                    }
                }
            }
            .bio {
                margin-top: $medium-margin*2;
                font-size: $language-font-size*2;
            }
        }
    }
}

/* FOR VERY SMALL MONITORS */
@media screen and (max-width: $width-first) {
    .main-about {
        &__left {
            margin-bottom: $big-margin;
        }
        &__right {
            .about-circle-images {
                height: $big-circle-size;       
            }
        }
    }

    .main-activity {
        &__background {
            background-position-y: -50px;
        }
    }

    .main-resources {
        &__blocks {
            flex-direction: column;
            align-items: center;
            .resource-block {
                margin-bottom: $big-margin;
            }
        }
    }

    .main-team {
        &__members {
            flex-direction: column;
            align-items: center;
            .member {
                margin-bottom: $big-margin;
            }
        }
    }
}

@media screen and (max-width: $width-mobile) {
    .cover {
        display: flex;
        justify-content: center;
        background: none;
        height: auto;
        &__urbanica {
            z-index: 1;
            position: inherit;
            bottom: 0;
            svg {
                cursor: pointer;
                #Image_24-2 {
                    fill: #6499d9;
                }
            }
        }
    }

    .main-about {
        &__left {
            margin-bottom: $big-margin;
            text-align: center;
        }
        &__footer {
            justify-content: center;
        }
    }

    .main-activity {
        height: auto;
        &__background {
            background-position-y: 0;
            background-position-x: -125px;
        }
        h1 {
            white-space: pre-wrap !important;
        }
        .main-activity-flex {
            display: flex;
            flex-direction: column !important;
            align-items: center;
        }
        &__central-block {
            width: auto;
        }
        &__activities {
            position: inherit;
            bottom: 0;
            margin-top: -1 * $hundred-margin;
            display: flex;
            flex-direction: column-reverse;
            .carousel-inner {
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: hidden;
                .carousel-item-prev:not(.carousel-item-right), .active.carousel-item-left {
                    display: none;
                }
                .carousel-item-prev:not(.carousel-item-left), .active.carousel-item-right {
                    display: none;
                }
            }
            .carousel-item {
                margin-right: 0;
                &.active {
                    display: flex;
                }
            }
            .carousel-indicators {
                position: inherit;
            }
        }
        .activity-block {
            margin-right: 0;
            margin-left: 0;
            margin-bottom: $big-margin;
        }
    }

    .main-sign-in {
        &__left {
            bottom: 0;
            top: auto;
            height: auto;
        }
        .start {
            margin-top: 0;
            width: 95px;
            height: 95px;
        }
        .end {
            margin-top: 0;
            width: 95px;
            height: 95px;
        }
    }

    .main-resources {
        margin-top: 0;
        height: auto;
    }
}