.profile-header {
    width: 100%;
    position: relative;
    svg {
        .st0 {
            fill: #F3B13B;
        }
    }
    &__avatar {
        position: absolute;
        width: 21.6vw;
        height: 21.6vw;
        top: 23%;
        left: 18.5%;
        border-radius: 50%;
        background-color: #fff;
        img {
            width: 21.6vw;
            height: 21.6vw;
            object-fit: cover;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__change-photo {
            display: none;
            position: absolute;
            overflow: hidden;
            width: 21.6vw;
            height: 21.6vw;
            bottom: 0;
            justify-content: center;
            align-items: flex-end;
            border-radius: 50%;
            .change-photo-inner {
                width: 100%;
                height: 80px;
                font-size: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: rgba(0,0,0,0.56);
                color: rgba(255,255,255,0.76);
                cursor: pointer;
            }
        }
        &:hover &__change-photo {
            display: flex;
        }
    }
    .ellipse {
        position: absolute;
        top: 62%;
        left: 14.5%;
        width: 6.5vw;
        height: 6.5vw;
        z-index: 2;
    }
    &__name {
        position: absolute;
        top: 48%;
        left: 45vw;
        text-align: center;
        color: #fff;
        p.name {
            font-size: 2vw;
            font-weight: 600;
            margin-bottom: 0.5vw;
        }
        p.role {
            font-size: 1vw;
        }
    }
    &__homepage {
        position: absolute;
        top: 40%;
        right: 25.8%;
        a {
            display: block;
            border-radius: 50%;
            width: 138px;
            height: 138px;
        }
    }
    &__logout {
        position: absolute;
        right: 30px;
        top: 90px;
        color: #fff;
        font-size: 26px;
        a {
            color: #fff;
        }
    }
}