@import '../../../variables.scss';
.urbanica-main {
    margin-top: -130px;
    &__what-is {
        font-weight: 600;
        font-style: italic;
        font-size: 30px;
        color: $main-blue;
        text-align: center;
        width: 650px;
    }
    &__motivation {
        font-weight: 600;
        font-style: italic;
        font-size: 40px;
        color: $urbanica-orange;
        text-align: center;
        margin-top: 11px;
        margin-bottom: 27px;
    }
    .urbanica-contest {
        font-family: Arial, Helvetica, sans-serif;
        //width: 750px;
        min-height: 306px;
        border-radius: 39px;
        background-color: $urbanica-pink;
        padding: 65px 144px 35px 144px;
        position: relative;
        margin-bottom: 40px;
        &__name {
            font-size: 35px;
            font-weight: 600;
        }
        &__description {
            font-size: 15px;
            //width: 225px;
            margin-bottom: 28px;
        }
        &__green {
            position: absolute;
            top: 0;
            left: 0;
            svg {
                height: 150px;
            }
        }
        &__girl {
            position: absolute;
            bottom: 0;
            right: 0;
            svg {
                width: 165px;
                height: calc((278px * 165) / 235);
            }
        }
    }
    // &__go-home {
    //     position: fixed;
    //     bottom: 130px;
    //     left: 20px;
    // }
}

.time-countdown {
    display: flex;
    margin-top: 20px;
    &__number {
        width: 18px;
        height: 32px;
        background-color: #fff;
        border-radius: 3px;
        margin-right: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: $standart-shadow;
        &:last-child {
            margin-right: 0;
        }
    }
    &__separator {
        display: flex;
        align-items: center;
        margin: 0 3px 0 0;
    }
}

.urbanica-info {
    font-size: 15px;
    color: $block-text-color;
    font-family: 'Candara';
    &__left {
        display: flex;
        position: relative;
    }
    &__left-text {
        max-width: 650px;
        margin-left: 100px;
        margin-top: 100px;
        h1 {
            color: $main-blue;
        }
    }
    &__right {
        display: flex;
        justify-content: flex-end;
        margin-top: -220px;
        position: relative;
        z-index: -1;
    }
    &__right-text {
        max-width: 650px;
        margin-right: 100px;
        margin-top: 200px;
        h1 {
            color: $main-blue;
        }
    }
    &__go-home {
        position: absolute;
        bottom: 45px;
        left: 200px;
        display: block;
        z-index: 4;
    }
    &__left-circles {
        position: absolute;
        left: 130px;
        bottom: 60px;
    }
}

.urbanica-competition {
    &__footer {
        margin-bottom: $big-margin;
        display: flex;
        button {
            &:first-child {
                margin-right: $medium-margin;
            }
        }
    }
    .success {
        margin-bottom: $big-margin;
    }
}

.urbanica-container {
    position: relative;
    background-color: #fff;
    border-radius: $standart-radius;
    &:first-child {
        margin-top: $urbanica-margin;
    }
    &:last-child {
        padding-bottom: 170px;
        margin-bottom: 140px;
    }
    &__bhrc-logo {
        position: absolute;
        bottom: 0;
        transform: translate(0, 50%);
    }
    &__subtitle {
        margin-top: $menu-margin;
        width: 100%;
    }
    &__project-name {
        margin: 30px 0;
        display: flex;
        align-items: center;
        .urbanica-select {
            margin-left: 20px;
        }
    }
    &__general-info {
        width: 100%;
        h2 {
            margin-bottom: 30px !important;
        }
        p {
            font-weight: bold;
            font-size: $footer-font-size;
            font-style: italic;
            color: $block-text-color;
            margin-top: $big-margin;
        }
        .flex-row {
            margin-top: $big-margin;
            p {
                margin-top: 0;
                margin-right: 40px;
                margin-bottom: $big-margin;
            }
            textarea {
                height: 135px;
            }
        }
    }
    label {
        font-size: 20px;
        margin-right: 40px;
        margin-left: 10px;
    }
}

.budget-table {
    &__row {
        display: flex;
        width: 100%;
        margin-bottom: 17px;
    }
    &__th {
        &-1 {
            width: 240px;
            margin-right: $big-margin;
        }
        &-2 {
            width: 80px;
            margin-right: $big-margin;
        }
        &-3 {
            width: 125px;
            margin-right: $big-margin;
        }
        &-4 {
            width: 180px;
            display: flex;
            justify-content: flex-end;
            margin-right: $big-margin;
        }
        &-5 {
            display: flex;
            align-items: center;
        }
    }
}

@media screen and (min-width: $width-4K) {
    .urbanica-main {
        margin-top: -260px;
        &__what-is {
            font-size: 60px;
            width: 1300px;
        }
        &__motivation {
            font-size: 80px;
            margin-top: 22px;
            margin-bottom: 54px;
        }
        .urbanica-contest {
            width: 1500px;
            min-height: 612px;
            border-radius: 78px;
            padding: 130px 460px 70px 288px;
            margin-bottom: 80px;
            &__name {
                font-size: 70px;
            }
            &__description {
                font-size: 30px;
                width: 450px;
                margin-bottom: 56px;
            }
            &__green {
                svg {
                    height: 300px;
                }
            }
            &__girl {
                svg {
                    transform: scale(2);
                    transform-origin: bottom right;
                }
            }
        }
    }
    .time-countdown {
        margin-top: 40px;
        &__number {
            width: 36px;
            height: 64px;
            border-radius: 6px;
            margin-right: 6px;
        }
        &__separator {
            margin: 0 6px 0 0;
        }
    }
    .urbanica-info {
        font-size: 30px;
        &__left {
            svg {
                width: 748px;
                height: 928px;
            }
            .bhrc-go-home {
                width: 260px;
                height: 226px;
            }
        }
        &__left-text {
            max-width: 1300px;
            margin-left: 200px;
            margin-top: 200px;
        }
        &__right {
            margin-top: -440px;
            svg {
                width: 816px;
                height: 900px;
            }
        }
        &__right-text {
            max-width: 1300px;
            margin-right: 200px;
            margin-top: 400px;
        }
        &__go-home {
            bottom: 90px;
            left: 400px;
        }
        &__left-circles {
            left: 260px;
            bottom: 120px;
            width: 280px !important;
            height: 188px !important;
        }
    }
}