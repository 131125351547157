@import '../../../variables.scss';
.header {
    padding-top: $big-margin*2;
    &__follow {
        position: absolute;
        top: $big-margin;
        right: $big-margin;
        display: flex;
        height: $bhrc-button-height;
        z-index: 2;
        font-size: $follow-font-size;
        font-weight: 600;
        color: $main-orange;
        transform: translate(0, -50%);
        .icon {
            margin-left: $small-margin;
            svg {
                width: $bhrc-button-height;
                height: $bhrc-button-height;
            }
            path {
                fill: $main-orange;
            }
        }
    }
    .white-header {
        position: fixed;
        background-color: #fff;
        z-index: 16;
        width: 100%;
        top: 0;
        //border-bottom: 1px solid rgba(0,0,0, 0.1);
        box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.1);
        .languages {
            .lang {
                color: #fff;
                //background-color: $main-orange;
                background-color: #fff;
            }
        }
        .navigation {
            &__search {
                span {
                    color: $main-orange;
                    border-color: $main-orange;
                }
            }
        }
    }
    &__navbar {
        display: flex;
        justify-content: center;
        background-color: $main-orange;
    }
}

.languages {
    display: flex;
    width: fit-content;
    justify-content: space-between;
    margin-left: $big-margin;
    .lang {
        display: flex;
        justify-content: center;
        align-items: center;
        width: $menu-round;
        height: $menu-round;
        border-radius: 50%;
        color: $main-orange;
        font-weight: 700;
        margin-right: $small-margin;
        position: relative;
        background-color: #fff;
        font-size: $language-font-size;
        cursor: pointer;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            // background-color: $main-white-hover;
            border: 2px solid $main-orange;
        }
        input[type='radio'] {
            opacity: 0;
            position: absolute;
            width: $menu-round;
            height: $menu-round;
            cursor: pointer;
        }
        &.checked {
            // background-color: $main-white-hover;
            border: 2px solid $main-orange;
        }
        label {
            margin-bottom: 0 !important;
        }
    }
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: $menu-height;
    width: $standart-container-width;
    position: relative;
    &__logo {
        z-index: 2;
        width: $main-logo-size;
        height: $main-logo-size;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: #0062A0;
        position: absolute;
        top: $menu-logo-top;
        left: 0;
        a {
            width: 100%;
            height: 100%;
        }
        svg {
            position: absolute;
            left: $menu-logo-svg-left;
            top: 50%;
            transform: translate(0, -50%);
            width: $main-logo-inner;
        }
        .st0 {
            //fill: $logo-blue;
            fill: #fff;
        }
        .st01 {
            fill: $logo-border;
        }
        .st1 {
            fill: #fff;
        }
    }
    &__element {
        margin-right: $menu-margin;
        &:last-child {
            margin-right: 0;
        }
        a {
            text-decoration: none;
            color: #fff;
            font-size: $menu-font-size;
            //font-weight: 600;
            transition: 0s color;
            &:hover {
                color: $main-white-hover;
                transition-duration: 0.3s;
            }
        }
        &.urbanica-menu {
            a {
                font-size: $menu-urbanica-font-size;
                font-weight: 500;
            }
        }
    }
}

.white-menu {
    top: 0;
    height: $menu-height;
    transition: 0.7s height;
    .menu__logo {
        width: fit-content;
        height: fit-content;
        background-color: transparent;
        top: 50%;
        align-items: center;
        svg {
            width: auto;
            height: $little-logo;
        }
        .st0 {
            fill: $logo-blue;
        }
        .st1 {
            fill: #000;
        }
    }
    .menu__element {
        a {
            color: #000;
            transition: 0s color;
            &:hover {
                color: $main-orange;
                transition-duration: 0.3s;
            }
        }
    }
}

.navigation {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    //width: $standart-container-width;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    &__search {
        span {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 50%;
            height: $menu-round;
            width: $menu-round;
            font-size: $language-font-size;
            svg {
                margin-left: 1px;
                margin-top: 1px;
            }
            &:hover {
                color: $main-white-hover;
                border: 1px solid $main-white-hover;
            }
        }
    }
}

@media screen and (min-width: $width-4K) {
    .header {
        padding-top: $big-margin*4;
        &__follow {
            position: absolute;
            top: $big-margin*2;
            right: $big-margin*2;
            height: $bhrc-button-height*2;
            font-size: $follow-font-size*2;
            .icon {
                margin-left: $small-margin*2;
                svg {
                    width: $bhrc-button-height*2;
                    height: $bhrc-button-height*2;
                }
            }
        }
    }
    .languages {
        margin-left: $big-margin*2;
        .lang {
            width: $menu-round*2;
            height: $menu-round*2;
            margin-right: $small-margin*2;
            font-size: $language-font-size*2;
            input[type='radio'] {
                width: $menu-round*2;
                height: $menu-round*2;
            }
        }
    }
    .menu {
        height: $menu-height*2;
        width: $standart-container-width*2;
        &__logo {
            width: $main-logo-size*2;
            height: $main-logo-size*2;
            border-radius: 50%;
            top: $menu-logo-top*2;
            left: 0;
            svg {
                left: $menu-logo-svg-left*2;
                width: $main-logo-inner*2;
            }
        }
        &__element {
            margin-right: $menu-margin*2;
            a {
                font-size: $menu-font-size*2;
            }
            &.urbanica-menu {
                a {
                    font-size: $menu-urbanica-font-size*2;
                }
            }
        }
    }
    .white-menu {
        height: $menu-height*2;
        .menu__logo {
            svg {
                height: $little-logo*2;
            }
        }
    }
    .navigation {
        &__search {
            span {
                border: 2px solid #fff;
                height: $menu-round*2;
                width: $menu-round*2;
                font-size: $language-font-size*2;
                svg {
                    margin-left: 2px;
                    margin-top: 2px;
                }
                &:hover {
                    border: 2px solid $main-white-hover;
                }
            }
        }
    }
}

@media screen and (max-width: $width-mobile) {
    .header {
        padding-top: 15px;
        &__navbar {
            background-color: transparent;
        }
    }

    .menu {
        height: 48px;
        margin-bottom: 15px;
        padding: 0 30px;
        justify-content: space-between;
        &__logo {
            width: 48px;
            height: 48px;
            top: 0;
            position: inherit;
            svg {
                width: 46px;
                transform: translate(-7%, -50%);
            }
        }
        &__navbar {
            cursor: pointer;
            svg { 
                transform: rotate(0deg);
                transition: 0.5s transform ease-in-out;
                &:hover {
                    transform: rotate(180deg);
                    transition: 0.5s transform ease-in-out;
                }
            }
        }
    }

    .white-header {
        display: flex;
        align-items: center;
        .menu {
            //height: auto;
            margin-bottom: 0;
            padding: 40px 30px;
        }
    }

    .mobile-nav {
        position: fixed;
        cursor: auto;
        top: 0;
        right: 0;
        z-index: 1000;
        transform: translateX(100%);
        transition: transform 0.5s cubic-bezier(0.39, 0.58, 0.57, 1);;
        width: 325px;
        height: 100vh;
        background: rgba(242,146,28, 0.91);
        background: linear-gradient(90deg, rgba(242,146,28,0.91) 0%, rgba(242,145,27,0.91) 50%, rgba(242,197,61,0.91) 100%);
        padding: 27px 22px;
        ul {
            list-style-type: none;
            padding: 0;
            li {
                margin-bottom: $big-margin;
                font-family: 'Candara';
                font-size: 20px;
            }
            a {
                color: #fff;
                text-decoration: none;
            }
        }
        &.opened {
            transform: translateX(0);
            transition: transform 0.5s cubic-bezier(0.39, 0.58, 0.57, 1);
        }
        &__header {
            font-size: 40px;
            margin-top: -7px;
            color: #fff;
            display: flex;
            justify-content: flex-end;
            svg {
                cursor: pointer;
                transform: rotate(0);
                &:hover {
                    transform: rotate(360deg);
                    transition: 0.1s transform ease-in-out;
                }
            }
            &.white-header {
                width: 100vw;
            }
        }
        &__lang {
            display: flex;
            justify-content: center;
            margin-bottom: 16px;
        }
        &__footer {
            color: #fff;
            border-top: 2px solid #fff;
            padding-top: 30px;
            svg {
                fill: #fff;
                height: 26px;
                .st0 {
                    fill: #fff;
                }
                .st01 {
                    fill: #000;
                }
            }
            &__social {
                display: flex;
                margin-top: 16px;
                svg {
                    width: 18px;
                    height: 18px;
                    margin-right: 6px;
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .languages {
        margin-left: 0;
    }
}