@import '../../../variables.scss';
.footer {
    padding: $menu-padding;
    background-color: $footer-color;
    width: 100%;
    bottom: 0;
    color: #fff;
    &__information {
        display: flex;
        justify-content: space-between;
        font-size: $footer-font-size;
        font-family: Arial, Helvetica, sans-serif;
        h2 {
            margin-bottom: $big-margin !important;
        }
        .footer-col {
            width: $footer-col;
        }
        .contact-info {
            color: $main-orange;
            display: flex;
            align-items: center;
            margin-bottom: $medium-margin;
            svg {
                margin-right: $footer-margin;
            }
        }
        a {
            color: #fff;
        }
        .info-item {
            display: block;
            line-height: 1.2;
            margin-bottom: $medium-margin;
            color: #fff;
            cursor: pointer;
        }
    }
    &__copyright {
        width: $copyright-width;
        border-top: 2px solid $footer-line-color;
        padding: $copyright-padding 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
        .text {
            margin-left: $copyright-margin;
            font-size: $h3-font-size;
        }
    }
    &__logo {
        width: auto;
        height: $footer-logo-height;
        .st0 {
            fill: $logo-white;
        }
        .st01 {
            fill: $logo-border;
        }
        .st1 {
            fill: $logo-white;
        }
    }
    
}

.footer-modal {
    .modal-dialog {
        max-width: 800px;
    }
    .modal-content {
        align-items: flex-start;
        //width: auto;
        width: 800px;
        height: 600px;
    }
    .modal-header {
        display: block;
    }
    .modal-body {
        overflow-y: scroll;
    }
    .thead {
        font-weight: 600;
    }
}

@media screen and (min-width: $width-4K) {
    .footer {
        padding: $menu-padding*2;
        &__information {
            font-size: $footer-font-size*2;
            h2 {
                margin-bottom: $big-margin*2 !important;
            }
            .footer-col {
                width: $footer-col*2;
            }
            .contact-info {
                margin-bottom: $medium-margin*2;
                svg {
                    margin-right: $footer-margin*2;
                }
            }
            .info-item {
                margin-bottom: $medium-margin*2;
            }
        }
        &__copyright {
            width: $copyright-width*2;
            border-top: 4px solid $footer-line-color;
            .text {
                margin-left: $copyright-margin*2;
                font-size: $h3-font-size*2;
            }
        }
        &__logo {
            height: $footer-logo-height*2;
        }
    }
}

@media screen and (max-width: $width-first) {
    .footer {
        &__information {
            flex-direction: column;
            align-items: center;
            .footer-col {
                width: 100%;
                margin-bottom: $big-margin;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        &__copyright {
            width: 100vw;
        }
    }
}

@media screen and (max-width: $width-mobile) {
    .footer {
        &__information {
            .footer-col {
                width: 90%;
                text-align: center;
            }
        }
        &__copyright {
            width: 100%;
            align-items: flex-end;
            .text {
                margin-left: 10px;
                font-size: 10px;
            }
        }
    }
}