@import '../../../variables.scss';
.urbanica-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    &__left {
        height: fit-content;
        position: relative;
        svg {
            margin-left: -9px;
            margin-top: -6px;
        }
        &__leaves {
            .top,
            .bottom {
                position: absolute;
            }
            .top {
                left: -50px;
                top: 4px;
            }
            .bottom {
                top: 196px;
                left: -9px;
            }
        }
    }
    &__right {
        height: fit-content;
        position: relative;
    }
    &__logo {
        position: absolute;
        left: 120px;
        top: 120px;
    }
    &__sign-up {
        position: absolute;
        bottom: 0;
        left: 150px;
    }
    .header-go-home {
        left: 410px;
        &__round-link {
            width: 106px;
            height: 106px;
            border-radius: 50%;
            color: #fff;
            display: flex;
            background-color: $main-blue;
            font-size: 60px;
            justify-content: center;
            align-items: center;
            svg {
                transform: translate(2%, -12%);
            }
        }
    }
}

@media screen and (min-width: $width-4K) {
    .urbanica-header {
        &__left {
            height: 800px;
            svg {
                transform: scale(2);
                transform-origin: 10px 6px;
            }
            &__leaves {
                .top {
                    left: -100px;
                    top: 8px;
                }
                .bottom {
                    top: 392px;
                    left: -18px;
                }
            }
        }
        &__right {
            svg {
                width: 1436px;
                height: 1400px;
                //transform: scale(2);
                //transform-origin: 100% 0;
            }
        }
        &__logo {
            left: 240px;
            top: 240px;
            svg {
                transform: scale(2);
                transform-origin: left;
            }
        }
        &__sign-up {
            left: 300px;
        }
    }
}