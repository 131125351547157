/* Colors */

$logo-white: #fff;
$logo-blue: #25a9e0;
$logo-border: #f15a24;
$logo-border-hover: #d8874d;
$footer-color: #0062a0;
$main-blue: #0062a0;
$main-blue-hover: #1971a8;
$footer-line-color: #0871B4;
$main-orange: #f79501;
$main-orange-hover: #fa7753;
$main-white-hover: #f7cbac;
$progress-color: #ffa380;
$main-grey: #f8f8f8;
$main-black: #1D1514;
//$main-white-hover: #0062a0;
$blue-opacity: #0062A04F;
$block-text-color: #707070;
$block-text-color2: #190A14;
$border-on-grey: #939393;
$urbanica-orange: #f77400;
$urbanica-pink: #fcf0da;
$urbanica-light-pink: #fdf7ed;
$error-red: #f44336;
$success-green: #04aa6d;
$info-blue: #2196f3;
$warning-orange: #ff9800;

/* Sizes */

$social-icon: 20px;
$social-icon-hover: 30px;
$header-height: 200px;
$footer-height: 300px;
$small-icon-size: 16px;
$main-logo-size: 142px;
$main-logo-inner: 160px;
$little-logo: 40px;
$blue-round: 6px;

/* Font sizes */

$little-font-size: 10px;
$language-font-size: 12px;
$location-font-size: 13px;
$hashtag-font-size: 13px;
$main-font-size: 14px;
$follow-font-size: 15px;
$button-font-size: 15px;
$menu-font-size: 18px;
$menu-urbanica-font-size: 25px;
$urbanica-button-font-size: 26px;
$h1-font-size: 35px;
$h2-font-size: 25px;
$h3-font-size: 18px;
$text-font-size: 13px;
$block-title-font-size: 18px;
$footer-font-size: 20px;

/* Borders */

$menu-border: 1px solid rgba(202, 199, 199, 0.7);

/* Margins */

$footer-margin: 5px;
$modal-footer-top: 10px;
$modal-input-margin: 15px;
$medium-margin: 15px;
$menu-margin: 20px;
$search-margin: 24px;
$cover-photo-margin: 145px;
$urbanica-button-bottom: 50px;
$small-circle-top: 44px;
$menu-logo-top: -25px;
$menu-logo-svg-left: 12px;
$main-activity-bottom: -25px;
$main-indicator-bottom: -50px;
$copyright-margin: 100px;
$article-info-top: 200px;
$article-info-left: 7px;
$article-title-top: 7px;
$article-date-top: 12px;
$post-image-right: 10px;
$news-content-margin: -65px;
$news-content-elem: 10px;
$category-min-width: 290px;
//-------------------------
$little-margin: 5px;
$small-margin: 8px;
$line-margin: 10px;
$medium-margin: 15px;
$block-margin: 24px;
$big-margin: 30px;
$large-margin: 75px;
$hundred-margin: 100px;
$urbanica-margin: -215px;

/* Paddings */

$little-padding: 5px;
$small-padding: 10px;
$menu-padding: 20px;
$modal-padding: 20px;
$medium-padding: 26px;
$modal-field-top: 15px;
$block-padding: 48px;
$carousel-block-padding-v: 30px;
$carousel-block-padding-h: 40px;
$copyright-padding: 15px;
$articles-top-padding: 45px;
$article-body-top: 64px;
$article-body-bottom: 56px;
$topics-x: 40px;
$topics-y: 30px;
$topic-x: 12px;
$topic-y: 18px;
$search-y: 10px;
$input-padding-left: 15px;
$input-padding-right: 60px;
$popular-v: 15px;
$popular-left: 30px;
$popular-right: 35px;
$news-v: 40px;
$news-h: 50px;

/* Widths */

$circle-border-size: 10px;
$standart-container-width: 900px;
$urbanica-container-width: 1100px;
$main-search-width: 400px;
$paint-width: 111px;
$paint-height: 26px;
$left-side-width: 375px;
$right-side-width: 348px;
$litle-circle-size: 200px;
$big-circle-size: 265px;
$modal-max-width: 600px;
$modal-content-width: 600px;
$modal-field-width: 300px;
$modal-input-width: 200px;
$modal-footer-width: $modal-input-width;
$central-subtitle-width: 430px;
$main-activity-width: 360px;
$main-indicator-width: 73px;
$main-resource-width: 270px;
$main-signin-container: 515px;
$member-width: 230px;
$footer-col: 200px;
$copyright-width: 711px;
$news-left: 735px;
$news-right: 350px;
$post-image-width: 85px;
$resource-material-width: 200px;
$resource-doc-width: 500px;
$resource-icon-width: 40px;

/* Heights */

$mini-button-height: 22px;
$bhrc-button-height: 20px;
$menu-height: 90px;
$main-activity-height: 460px;
$main-activity-back-height: 350px;
$main-search-height: 30px;
$modal-content-height: 450px;
$modal-input-height: 28px;
$main-activity-height: 240px;
$main-indicator-height: 2px;
$little-height: 4px;
$main-resource-height: 325px;
//$main-resource-small: 160px;
$main-resource-small: 100px;
$main-resource-hover: 141px;
$main-sign-in: 230px;
//$member-height: 266px;
$footer-logo-height: 50px;
$news-cover-height: 560px;
$news-p-height: 450px;
$topic-height: 90px;
$article-height: 280px;
$article-info-height: 150px;
$article-search-height: 80px;
$post-image-height: 70px;
$news-height: 400px;
$resources-head-height: 365px;
$resources-category-height: 200px;
$resource-frame-height: 165px;
$resource-icon-height: 50px;
$screen-height: calc(100vh - 90px);

/* Background-positions */

$main-activity-y: -170px;

/* Round elements */

$menu-round: 25px;
$urbanica-badge-round: 36px;

/* Border radiuses */

$indicator-radius: 2px;
$resources-radius: 5px;
$standart-radius: 7px;
$resources-radius-big: 12px;
$urbanica-input-radius: 15px;
$button-radius: 20px;
$main-search-radius: 24px;

/* Shadows */

$standart-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
$profile-shadow: 3px 3px 6px 3px rgba(0, 0, 0, 0.16);

/* Button sizes */

$button-padding: 13px;
$button-height: 37px;
$orange-margin-right: 33px;
$white-button-height: 22px;
$urbanica-signup-button-height: 55px;
$urbanica-orange-button-height: 40px;
$urbanica-button-padding: 35px;
$urbanica-orange-padding: 25px;
$urbanica-orange-height: 40px;
$urbanica-border-radius: 26px;

/* Screen sizes */

$width-full-HD: 1920px;
$width-4K: 3840px;
$width-first: 919px;
$width-mobile: 752px