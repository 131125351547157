@import '../../../variables.scss';
.articles {
    padding: $articles-top-padding 0;
}

.articles-body {
    background-color: $main-grey;
    padding: $article-body-top 0 $article-body-bottom 0;
    &__left {
        display: flex;
        width: $news-left;
        justify-content: space-between;
        flex-wrap: wrap;
        .article {
            width: $news-right;
            height: $article-height;
            position: relative;
            background-size: cover;
            margin-bottom: $hundred-margin;
            img {
                width: $news-right;
                height: $article-height;
                object-fit: cover;
            }
            &__info {
                position: absolute;
                background-color: #fff;
                top: $article-info-top;
                left: $article-info-left;
                width: $news-right - 15px;
                height: $article-info-height;
                padding: 0 $menu-padding;
                border-radius: $standart-radius;
                box-shadow: $standart-shadow;
                text-align: center;
                h4 {
                    font-size: $footer-font-size;
                    font-style: italic;
                    font-weight: 600;
                    color: $block-text-color;
                    margin-top: $article-title-top;
                }
                &__date {
                    font-size: $little-font-size;
                    text-align: center;
                    margin-top: $article-date-top;
                }
            }
        }
    }
    &__right {
        width: $news-right;
        .search-block {
            width: 100%;
            height: $article-search-height;
            background-color: #fff;
            border-radius: $standart-radius;
            box-shadow: $standart-shadow;
            padding: $search-y $topic-x;
            position: relative;
            margin-bottom: $menu-margin;
            div {
                &:first-child {
                    height: 100%;
                    position: relative;
                }
            }
            input[type='text'] {
                background-color: $main-grey;
                border-radius: $standart-radius;
                font-size: $block-title-font-size;
                width: 100%;
                height: 100%;
                outline: none;
                border: none;
                padding: 0 $input-padding-right 0 $input-padding-left;
                &::placeholder {
                    color: $block-text-color;
                }
            }
            .search-icon {
                position: absolute;
                right: $big-margin;
                top: 50%;
                transform: translate(0, -50%);
            }
            &__item {
                padding: 20px;
                border-bottom: 1px solid #cecece;
                a {
                    display: flex;
                    text-decoration: none;
                }
                &.highlighted {
                    background-color: #c6c1c1;
                }
            }
            &__item-image {
                width: $post-image-width;
                height: $post-image-height;
                object-fit: cover;
                margin-right: $post-image-right;
            }
        }
        .popular-block {
            width: 100%;
            background-color: #fff;
            border-radius: $standart-radius;
            box-shadow: $standart-shadow;
            padding: $popular-v $popular-right $popular-v $popular-left;
            color: $block-text-color;
            h4 {
                font-size: $footer-font-size;
                font-style: italic;
                font-weight: 600;
                color: $block-text-color;
                margin-top: $article-title-top;
                margin-bottom: $menu-margin;
                padding-bottom: $popular-v;
                border-bottom: 1px solid $main-orange;
            }
            .post {
                display: flex;
                height: $article-search-height;
                img {
                    width: $post-image-width;
                    height: $post-image-height;
                    object-fit: cover;
                    margin-right: $post-image-right;
                }
                &__date {
                    font-size: $little-font-size;
                    h5 {
                        font-size: $button-font-size;
                        font-weight: 600;
                        font-style: italic;
                    }
                }
            }
        }
    }
}

.blue-round {
    width: $blue-round;
    height: $blue-round;
    border-radius: 50%;
    background-color: $main-blue;
    display: inline-block;
}

.news {
    &__image {
        width: 100%;
        img {
            width: 100%;
            height: $news-height;
            object-fit: cover;
        }
    }
    &__content {
        background-color: #fff;
        border-radius: $standart-radius;
        box-shadow: $standart-shadow;
        width: calc(100% - 14px);
        padding: $news-v $news-h;
        margin: $news-content-margin auto 0 auto;
        color: $block-text-color;
        line-height: 2;
        font-size: $button-font-size;
        figure {
            &>div:first-child {
                width: 100%;
            }
        }
    }
    &__date {
        font-size: $button-font-size;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: $news-content-elem;
        color: $block-text-color;
        display: flex;
        justify-content: space-between;
    }
    &__title {
        font-size: $footer-font-size;
        font-style: italic;
        font-weight: 600;
        color: $block-text-color;
        margin-bottom: $news-content-elem;
    }
    &__viewed {
        margin-left: $line-margin;
        svg {
            margin-bottom: 3px;
        }
    }
}

@media screen and (min-width: $width-4K) {
    .articles {
        padding: $articles-top-padding*2 0;
    }
    .articles-body {
        padding: $article-body-top*2 0 $article-body-bottom*2 0;
        &__left {
            width: $news-left*2;
            .article {
                width: $news-right*2;
                height: $article-height*2;
                margin-bottom: $hundred-margin*2;
                img {
                    width: $news-right*2;
                    height: $article-height*2;
                }
                &__info {
                    top: $article-info-top*2;
                    left: $article-info-left*2;
                    width: ($news-right - 15px)*2;
                    height: $article-info-height*2;
                    padding: 0 $menu-padding*2;
                    border-radius: $standart-radius*2;
                    h4 {
                        font-size: $footer-font-size*2;
                        margin-top: $article-title-top*2;
                    }
                    &__date {
                        font-size: $little-font-size*2;
                        margin-top: $article-date-top*2;
                    }
                }
            }
        }
        &__right {
            width: $news-right*2;
            .search-block {
                height: $article-search-height*2;
                border-radius: $standart-radius*2;
                padding: $search-y*2 $topic-x*2;
                margin-bottom: $menu-margin*2;
                input[type='text'] {
                    border-radius: $standart-radius*2;
                    font-size: $block-title-font-size*2;
                    padding: 0 $input-padding-right*2 0 $input-padding-left*2;
                }
                .search-icon {
                    right: $big-margin*2;
                }
            }
            .popular-block {
                border-radius: $standart-radius*2;
                padding: $popular-v*2 $popular-right*2 $popular-v*2 $popular-left*2;
                h4 {
                    font-size: $footer-font-size*2;
                    margin-top: $article-title-top*2;
                    margin-bottom: $menu-margin*2;
                    padding-bottom: $popular-v*2;
                }
                .post {
                    height: $article-search-height*2;
                    img {
                        width: $post-image-width*2;
                        height: $post-image-height*2;
                        margin-right: $post-image-right*2;
                    }
                    &__date {
                        font-size: $little-font-size*2;
                        h5 {
                            font-size: $button-font-size*2;
                        }
                    }
                }
            }
        }
    }
    .blue-round {
        width: $blue-round*2;
        height: $blue-round*2;
    }
    .news {
        &__image {
            img {
                height: $news-height*2;
            }
        }
        &__content {
            border-radius: $standart-radius*2;
            width: calc(100% - 28px);
            padding: $news-v*2 $news-h*2;
            margin: $news-content-margin*2 auto 0 auto;
            font-size: $button-font-size*2;
        }
        &__date {
            font-size: $button-font-size*2;
            margin-bottom: $news-content-elem*2;
        }
        &__title {
            font-size: $footer-font-size*2;
            margin-bottom: $news-content-elem*2;
        }
        &__viewed {
            margin-left: $line-margin*2;
            svg {
                margin-bottom: 6px;
            }
        }
    }
}

@media screen and (max-width: $width-mobile) {
    .articles-body {
        padding: 20px 0 56px 0;
    }
    .search-block {
        width: $news-right;
        height: $article-search-height;
        background-color: #fff;
        border-radius: $standart-radius;
        box-shadow: $standart-shadow;
        padding: $search-y $topic-x;
        position: relative;
        margin-bottom: $menu-margin;
        div {
            &:first-child {
                height: 100%;
                position: relative;
            }
        }
        input[type='text'] {
            background-color: $main-grey;
            border-radius: $standart-radius;
            font-size: $block-title-font-size;
            width: 100%;
            height: 100%;
            outline: none;
            border: none;
            padding: 0 $input-padding-right 0 $input-padding-left;
            &::placeholder {
                color: $block-text-color;
            }
        }
        .search-icon {
            position: absolute;
            right: $big-margin;
            top: 50%;
            transform: translate(0, -50%);
        }
        &__item {
            padding: 20px;
            border-bottom: 1px solid #cecece;
            a {
                display: flex;
                text-decoration: none;
            }
            &.highlighted {
                background-color: #c6c1c1;
            }
        }
        &__item-image {
            width: $post-image-width;
            height: $post-image-height;
            object-fit: cover;
            margin-right: $post-image-right;
        }
    }
    .popular-block {
        margin-top: $big-margin;
        width: 100%;
        background-color: #fff;
        border-radius: $standart-radius;
        box-shadow: $standart-shadow;
        padding: $popular-v $popular-right $popular-v $popular-left;
        color: $block-text-color;
        h4 {
            font-size: $footer-font-size;
            font-style: italic;
            font-weight: 600;
            color: $block-text-color;
            margin-top: $article-title-top;
            margin-bottom: $menu-margin;
            padding-bottom: $popular-v;
            border-bottom: 1px solid $main-orange;
        }
        .post {
            display: flex;
            height: $article-search-height;
            img {
                width: $post-image-width;
                height: $post-image-height;
                object-fit: cover;
                margin-right: $post-image-right;
            }
            &__date {
                font-size: $little-font-size;
                h5 {
                    font-size: $button-font-size;
                    font-weight: 600;
                    font-style: italic;
                }
            }
        }
    }
}